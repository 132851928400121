<template>
	<div>
		<common-table
				:itemScope="['mainstatus']"
				:headers="headers"
				:listData="listData"
				@changePage="changePage"
				:loading="loading"
				:btnAction="true"
				ref="ct"
				pageTitle="退款申请记录"
				:customHtml="true"
				:btnActionEvent="btnActionEvent"
				@otherTableOperate="otherTableOperate"
		>
			<template v-slot:mainstatus="{ item }">
				<!-- 格式化订单状态 -->
				<formatter-field :status="item.mainstatus" field="orderStatus"/>
			</template>
			<template v-slot:customHtml>
				<advanced-search
						:fixedConfig="fixedConfig"
						:othersConfig="othersConfig"
						@search="search"
						:extraParams="extraParams"
						@advancedSearch="advancedSearch"
						:hasExportFile="true"
						@exportFile="exportFile"
				/>
			</template>
		</common-table>
		<template v-if="dialog">
			<exhibition-data-box :width="936" :contentHeight="500" :dialog.sync="dialog" usageMode="tabs" :items="items">
				<template v-slot:footer>
					<div class="mt-4" v-if="showOperateBtn">
						<v-btn
								:disabled="!operatePermissions.includes('82424')"
								depressed
								:loading="btnLoading"
								color="redness white--text"
								@click="forcedRefund">
							强制退款
						</v-btn>
						<div style="float: right">
							<v-btn
									:loading="btnLoading"
									:disabled="!operatePermissions.includes('82423')"
									color="redness white--text"
									@click="refusedRefund"
									depressed>
								拒绝退款
							</v-btn>
							<v-btn
									:loading="btnLoading"
									:disabled="!operatePermissions.includes('82422')"
									color="primary"
									@click="confirmRefund"
									class="ml-4"
									depressed>
								确认退款
							</v-btn>
						</div>
					</div>
				</template>
			</exhibition-data-box>
		</template>
	</div>
</template>

<script>
import commonCurd from "@/mixin/commonCurd";
import api from '@/api/orderManage/apply-for-refund.js';
import advancedSearch from "@/components/advancedSearch/advancedSearch.vue";
import exhibitionDataBox from '@/components/exhibition-data-box/exhibition-data-box.vue';
import detailsOrder from '../common-order-js/details-order.js';
import detailsAppointment from '../common-order-js/details-appointment.js';
import detailsHotel from '../common-order-js/details-hotel.js';
import detailsTicket from '../common-order-js/details-ticket.js';
import detailsCombo from '../common-order-js/details-combo.js';
import orderDetailsTabs from '../common-order-js/refund-details-tabs';
import formatterField from '../formatter.vue';

export default {
	// 退款申请
	components: {advancedSearch, exhibitionDataBox, formatterField},
	mixins: [commonCurd, detailsOrder, detailsHotel, detailsTicket, detailsCombo, orderDetailsTabs, detailsAppointment],
	data() {
		return {
			api,
			btnLoading: false,
			extraParams: '',
			dialog: false,
			showOperateBtn: false,
			// 设定一个订单号
			bookingid: '',
			ptype: '',
			items: [],
			btnActionEvent: [
				{
					event: 'getDetails',
					icon: 'chakan-fill',
					color: 'primary',
					tooltip: '详情'
				}
			],
			headers: [
				{text: '订单号/综合预订号', value: 'bookingid'},
				{text: '预订人姓名', value: 'bookername'},
				{text: '预订人电话', value: 'tel'},
				{text: '商品类型', value: 'ptypedesc'},
				{text: '订单金额', value: 'amount'},
				{text: '违约金额', value: 'debit'},
				{text: '退款金额', value: 'refund'},
				{text: '退款原因', value: 'reason', width: '120px'},
				{text: '审核状态', value: 'astatus'},
				{text: '支付方式', value: 'paymethod'},
				{text: '申请时间', value: 'createtime'},
				{text: '操作', value: 'actions', width: '80px'},
			],
			fixedConfig: [
				{type: 'input', label: '输入订单号/综合预订号', property: 'bookingid'},
				{type: 'select', label: '选择商品类型', property: 'ptype', items: []},
				{type: 'select', label: '选择审核状态', property: 'status', items: []}
			],
			othersConfig: [
				{type: 'select', label: '选择支付方式', property: 'payment', items: []},
				{type: 'input', label: '输入支付流水号', property: 'payid'},
				{type: 'dateTime', label: '开始日期', property: 'starttime'},
				{type: 'dateTime', label: '结束日期', property: 'endttime'}
			],
			operatePermissions: []
		}
	},
	methods: {
		advancedSearch() {
			this.$nextTick(() => {
				this.$refs.ct.getTableHeight();
			});
		},
		otherTableOperate(e, item) {
			this.ptype = item.ptype
			this.getOrderDetailsTabs(item, true, false);
		},
		// 确认退款
		confirmRefund() {
			this.axios.post(api.getRefundInfo, {bookingid: this.bookingid, ptype: this.ptype}).then((res) => {
				let msg = '确定确认退款？'
				if (res.data?.msg) {
					msg = res.data.msg
				}
				this.confirm.showConfirm(msg, 500).then(() => {
					this.refundOperate(api.confirmRefund);
				}).catch(() => {
				});
			})
		},
		// 拒绝退款
		refusedRefund() {
			this.confirm.showConfirm('确定拒绝退款？').then(() => {
				this.refundOperate(api.refusedRefund);
			}).catch(() => {
			});
		},
		// 强制退款
		forcedRefund() {
			this.confirm.showConfirm('确定强制退款？').then(() => {
				this.refundOperate(api.forcedRefund);
			}).catch(() => {
			});
		},
		// 退款操作
		refundOperate(api) {
			this.btnLoading = true
			this.axios.post(api, {bookingid: this.bookingid, ptype: this.ptype}).then(r => {
				this.btnLoading = false
				if (r.code === this.staticVal.Code.Success) {
					this.dialog = false;
					this.list();
					this.snackbar.success('操作成功');
				}
			});
		}
	},
	mounted() {
		let str = sessionStorage.getItem('operatePermissionKey');
		if (str) {
			this.operatePermissions = JSON.parse(str);
		}
		// 加载下拉框数据
		this.axios.post(this.select_data, {keyname: 'ptype,auditingstatus,payment'}).then((res) => {
			if (res.code === this.staticVal.Code.Success) {
				this.fixedConfig[1].items = res.data[0].values;
				this.fixedConfig[2].items = res.data[1].values;
				this.othersConfig[0].items = res.data[2].values;
			}
		});
	},
	created() {
		// 外部跳转的额外参数
		this.extraParams = this.$route.query.value;
	}
}
</script>

<style scoped lang="scss">

</style>
