export default {
	data() {
		return {
			appointmentItems: {
				deploy: {
					row: [
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'bookingid',
							connector: {
								props: {
									label: "预约单号",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'groupid',
							connector: {
								props: {
									label: "预约项目",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'statusdesc',
							connector: {
								props: {
									label: "订单状态",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'sitecode',
							connector: {
								props: {
									label: "预约场所",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'usedate',
							connector: {
								props: {
									label: "预约日期",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'period',
							connector: {
								props: {
									label: "预约时段",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'bookername',
							connector: {
								props: {
									label: "预约人",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'telephone',
							connector: {
								props: {
									label: "预约人手机号",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'roomno',
							connector: {
								props: {
									label: "预约房号",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'amount',
							connector: {
								props: {
									label: "预约总金额",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'payamount',
							connector: {
								props: {
									label: "实际支付金额",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'payment',
							connector: {
								props: {
									label: "支付方式",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'paytime',
							connector: {
								props: {
									label: "支付时间",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'payno',
							connector: {
								props: {
									label: "支付订单号",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'payid',
							connector: {
								props: {
									label: "支付流水号",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'createtime',
							connector: {
								props: {
									label: "创建时间",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'refund',
							connector: {
								props: {
									label: "退款金额",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'refundtime',
							connector: {
								props: {
									label: "退款时间",
									readonly: true
								}
							}
						}
					]
				}
			}
		}
	}
}
